<template>
  <div>
    <!-- questo componente non viene utilizzato come tab, ma viene importato nel tab General -->
    <!-- in caso contrario, basta aggiungere il tab nella table CommunicationGroupings -->
    <div class="mb-1">
      <b-row>
        <b-col align="right">
          <b-button
            class="btn-create"
            type="button"
            variant="primary"
            size="sm"
            @click="onAddCommunicationDetail"
            title="Crea Riga"
            v-if="canVerb(resource, 'store') && extras.status_procedure >= 3"
            ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
            Crea</b-button
          >
        </b-col>
      </b-row>
    </div>
    <communication-details
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :filterOn="{
        byRelations: ['byInsurancePayment', 'byInsuranceAncillary'],
      }"
      noInnerWidth
      @destroy="onDelete"
      @create="onCreate"
      :onlyActions="extras.status_procedure >= 3 ? ['destroy'] : []"
    >
    </communication-details>

    <add-communication-detail-modal
      @create="onCreate"
    ></add-communication-detail-modal>
  </div>
</template>

<script>
import CommunicationDetails from "@/components/tables/CommunicationDetails";
import AddCommunicationDetailModal from "@/components/modals/addCommunicationDetailModal";
// import { toLocaleCurrency } from "@/utils/strings";
// import { toLocaleDate } from "@/utils/dates";
import FormMixin from "@/mixins/FormMixin";
import { mapActions } from "vuex";
import { getDictionary } from "@/utils/dictionary";

export default {
  mixins: [FormMixin],
  name: "CommunicationDetail",
  data() {
    return {
      formLoaded: false,
      filter: this.initFilter(),
      filterName: "filterCommunicationDetail",
      repository: "communication_detail",
      resource: "communication_details",
      tableRef: "communicatioDetailTableRef",
      fields: [
        {
          key: "date",
          label: "Data",
        },
        {
          key: "policy",
          label: "Polizza",
        },
        {
          key: "description",
          label: "Descrizione",
        },
        {
          key: "gross",
          label: "Importo",
        },
      ],
    };
  },
  components: {
    CommunicationDetails,
    AddCommunicationDetailModal,
  },
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    getDictionary,
    initFilter() {
      let init = {
        byCommunicationGrouping: { id: this.resourceId },
      };
      return init;
    },
    onAddCommunicationDetail() {
      this.$bvModal.show("addCommunicationDetailModal");
    },
    onCreate(form) {
      form[this.repository].communication_grouping_id = this.resourceId;
      this.store(this.repository, form[this.repository])
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Riga creata`,
          });
          this.onSearch(this.filterName);
          this.$emit("loadIndex");
        })
        .catch((error) => {
          console.error(error);
          this.showError(error);
        });
    },
    onDelete(id) {
      this.delete(this.repository, id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Riga Eliminata`,
          });
          this.onSearch(this.filterName);
          this.$emit("loadIndex");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef]
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  mounted() {
    this.onSearch(this.filterName);
  },
  // beforeMount() {},
};
</script>

<style scoped>
.closed-insurance-policies {
  margin-top: 30px;
}
</style>

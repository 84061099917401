<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-row>
              <div class="form-group col-md-3">
                <base-datepicker
                  vid="date"
                  name="Data"
                  label="Data"
                  v-model="form.custom_data.date"
                  :rules="{ required: true }"
                />
              </div>
              <div class="form-group col-md-3">
                <base-input
                  vid="description"
                  name="Descrizione"
                  label="Descrizione"
                  v-model="form.custom_data.description"
                  placeholder="Inserisci una descrizione"
                  :rules="{ required: true }"
                />
              </div>
              <div class="form-group col-md-3">
                <base-currency
                  vid="gross"
                  name="Valore"
                  label="Valore"
                  v-model="form.custom_data.gross"
                  :options="{
                    currency: 'EUR',
                    locale: 'it-IT',
                    precision: 2,
                  }"
                  :rules="{ required: true }"
                />
                <!-- :rules="{ max_value: 0 }" -->
              </div>
            </b-row>

            <b-row>
              <div class="form-group col-md-3 align-self-end">
                <p>Aggiungi riga a tutti i raggruppamenti</p>
                <b-button
                  @click="handleSubmit(onSubmit('Y'))"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  :disabled="invalid"
                >
                  Aggiungi
                </b-button>
                <!-- :disabled="form.custom_data.gross > 0" -->
              </div>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseInput from "@/components/form/BaseInput";
import BaseIcon from "@/components/BaseIcon";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
export default {
  data() {
    return {
      isLoading: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
      form: {
        custom_data: {
          date: null,
          description: null,
          gross: null,
        },
      },
    };
  },
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseDatepicker,
    BaseCurrency,
  },
  methods: {
    resetLocalForm() {
      this.form = {
        custom_data: {
          date: null,
          description: null,
          gross: null,
        },
      };
    },
    onSubmit() {
      // console.log(this.form[this.repository]);
      this.isLoading = true;
      const Repo = RepositoryFactory.get(this.repository);
      Repo.details(this.resourceId, this.form)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Procedura Modificata`,
          });
          this.resetLocalForm();
          this.$emit("fetch");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      // change `true` to things checked on model, beyond scope of question
      // this.$emit("on-validate", this.$data, true);
      // this.$emit("on-validate", true, this.index);
      this.$emit("on-validate", true, this.index);
      return true;
    },
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-2">
    <div :style="[!this.noInnerWidth ? { width: width } : {}]">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <!-- <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button> -->

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Riprendi"
                :disabled="row.item.status_procedure.value == 5"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>

          <template #cell(date)="{ item }">
            {{ toLocaleDate(toReferringAt(item)) }}
          </template>

          <template #cell(policy)="{ item }">
            {{ toPolicy(item) }}
          </template>

          <template #cell(description)="{ item }">
            {{ toDescription(item) }}
          </template>

          <template #cell(gross)="{ item }">
            {{ toLocaleCurrency(toGross(item)) }}
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import BaseIcon from "@/components/BaseIcon";

export default {
  name: "CommunicationDetailsTable",
  extends: template,
  data() {
    return {};
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    toPolicy(item) {
      let ret = "";
      if (!item.insurance_payment) {
        console.log(`item.id ${item.id} has not insurance_payment`);
        return "";
      }
      let pt = item.insurance_payment.payment_type.value;
      try {
        switch (pt) {
          case 0:
          case 3:
            ret =
              item.insurance_payment.book_entry.various_accountings[0].pivot
                .insurance_policy_number;
            break;
          default:
            ret =
              item.insurance_payment.insurance_ancillary.insurance_policy
                .number;
        }
      } catch (error) {
        console.error(error);
      }
      return ret;
    },
    toReferringAt(item) {
      return item.insurance_payment_id && item.insurance_payment
        ? item.insurance_payment.referring_at
        : item.custom_data
        ? item.custom_data.date
        : "not found";
    },
    toDescription(item) {
      let ret = "";
      if (!item.insurance_payment) {
        return item.custom_data ? item.custom_data.description : "not found";
      }
      let pt = item.insurance_payment.payment_type.value;
      try {
        switch (pt) {
          case 0:
            ret = "sospeso";
            break;
          case 1:
            ret = "arretrato";
            break;
          case 2:
          case 4:
            // Da incassare
            // prodotto: item.insurance_payment.book_entry.various_accountings[0].pivot.insurance_risk.formatted_title
            //          tem.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title
            ret = `polizza ${item.insurance_payment.insurance_ancillary.insurance_policy.number} ${item.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title}`;

            break;
          case 3:
            ret = "acconto";
            break;

          default:
            break;
        }
      } catch (error) {
        console.error(error);
      }
      return ret;
    },
    toGross(item) {
      // Importo: mostra sempre il lordo del Q relazionato con la riga, se non c’è Q mostra il valore dal json dell’inserimento manuale
      let ret = "";
      if (!item.insurance_payment) {
        ret = item.custom_data ? item.custom_data.gross : "not found";
      } else {
        ret = item.insurance_payment.gross;
      }
      return ret;
    },
  },
  components: {
    BaseIcon,
    // General,
  },
};
</script>

<style lang="css" scoped></style>

<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <b-row>
          <b-col align="left">
            <b-button-group
              v-b-toggle:collapse-1
              class="my-2 filter-button-group"
              :title="getDefaultFilterMessage()"
            >
              <span class="when-open">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              <span class="when-closed">
                <b-icon icon="funnel" font-scale="1.5"></b-icon>
              </span>
              Filtra
            </b-button-group>
          </b-col>
          <b-col align="right">
            <b-button
              class="btn-create"
              type="button"
              variant="primary"
              size="sm"
              @click="onAdd"
              title="Crea"
              v-if="canVerb(resource, 'store')"
              ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
              Crea</b-button
            >
          </b-col>
        </b-row>

        <b-collapse visible id="collapse-1">
          <div class="mt-2">
            <b-form @submit.prevent="onSearch(filterName)">
              <b-card class="filter">
                <b-row>
                  <div class="col-md-3">
                    <base-select
                      name="status"
                      label="Stato"
                      v-model="filter.byAttribute.status_procedure"
                      :options="statusOptions"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="communication_type"
                      label="Tipo"
                      v-model="filter.byAttribute.communication_type"
                      :options="typeOptions"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-datepicker
                      vid="da"
                      name="Creazione da"
                      label="Creazione da"
                      v-model="filter.byCalendar.from"
                    />
                  </div>
                  <div class="col-md-3">
                    <base-datepicker
                      vid="a"
                      name="Creazione a"
                      label="Creazione a"
                      v-model="filter.byCalendar.to"
                    />
                  </div>
                  <div class="form-group col-md-3 align-self-end">
                    <b-button
                      type="submit"
                      variant="lisaweb"
                      size="sm"
                      v-b-toggle:collapse-1
                      >Cerca</b-button
                    >
                    <b-button
                      class="btn-reset"
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      @click="onClearFilter(filterName)"
                      >Reset</b-button
                    >
                  </div>
                </b-row>
              </b-card>
            </b-form>
          </div>
        </b-collapse>

        <communication-procedures
          @edit="onEdit"
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName"
          :ref="tableRef"
          :onlyActions="['edit']"
          :filterOn="{
            byRelations: ['byDocument'],
          }"
          @download="downloadDocument"
        ></communication-procedures>
        <div>
          <select-option-modal
            :opts="typeOptions"
            title="Seleziona il tipo"
            @select="onSelectOption"
          />
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import CommunicationProcedures from "@/components/tables/CommunicationProcedures";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import SelectOptionModal from "@/components/modals/selectOption";
import { mapGetters, mapActions } from "vuex";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import FormMixin from "@/mixins/FormMixin";
import DocumentsMixin from "@/mixins/DocumentsMixin";
import { toLocaleDate } from "@/utils/dates";
import { getInputType } from "@/utils/forms";

export default {
  mixins: [FormMixin, CustomFiltersMixin, DocumentsMixin],
  data() {
    return {
      isLoading: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
      filterName: "filterCommunicationProcedure",
      filter: this.initFilter(),
      form: {
        communication_procedure: {
          status_procedure: 0,
          communication_type: null,
        },
      },
      statusOptions: [
        { text: "Inizio", value: 0 },
        { text: "Esclusioni", value: 1 },
        { text: "Raggruppamenti", value: 2 },
        { text: "Modifica", value: 3 },
        { text: "Template", value: 4 },
        { text: "Invio", value: 5 },
      ],
      typeOptions: [
        { text: "Email", value: 0 },
        { text: "SMS", value: 1 },
        { text: "Stampa", value: 2 },
      ],
      tableRef: "CommunicationProceduresTableRef",
      fields: [
        {
          key: "status_procedure.text",
          label: "Stato",
        },
        {
          key: "communication_type.text",
          label: "Tipo",
        },
        {
          key: "created_date",
          formatter: (value) => toLocaleDate(value, "DD/MM/YYYY HH:mm:ss"),
          label: "Data creazione",
        },
        {
          key: "is_channel_excluded.text",
          label: "Esclusi per canale",
          class: "text-center",
        },
        {
          key: "is_broker_excluded.text",
          label: "Esclusi per produttore",
          class: "text-center",
        },
        {
          key: "files",
          label: "File generati",
          thStyle: {
            "min-width": "200px",
          },
        },
      ],
    };
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseSelect,
    CommunicationProcedures,
    SelectOptionModal,
  },
  mounted() {
    if (this.isShowAutoModal) this.$bvModal.show("selectOption");
  },
  created() {},
  beforeDestroy() {
    this.saveLisacomunicaAutoModal(false);
  },
  computed: {
    ...mapGetters("lisacomunica_automodal", ["isShowAutoModal"]),
  },
  methods: {
    getInputType,
    initFilter() {
      let init = {
        byAttribute: {
          status_procedure: null,
          communication_type: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byUser: {
          // filtro solo me MIE procedure
          id: this.$store.state.auth.user.id,
        },
      };
      return init;
    },
    onAdd() {
      this.$bvModal.show("selectOption");
    },
    onSelectOption(val) {
      console.debug("onSelectOption: ", val);
      if (val != null) {
        this.isLoading = true;
        this.fetchCreateForm(this.repository).then(() => {
          this.form[this.repository].communication_type = val;
          this.form[this.repository].status_procedure = 1;
          this.form[this.repository].is_vehicle_premium_shown = 1;
          this.form[this.repository].is_life_premium_shown = 1;
          this.form[this.repository].is_extra_vehicle_premium_shown = 1;

          console.log(this.form[this.repository]);
          this.store(this.repository)
            .then((response) => {
              this.isLoading = false;
              this.$showSnackbar({
                preset: "success",
                text: "Procedura creata correttamente",
              });
              let procedure_id = response.data.data.id;
              this.onEdit(procedure_id, response.data.data);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        });
      } else {
        this.$showSnackbar({
          preset: "info",
          text: "Selezionare il tipo",
        });
      }
    },
    onEdit(id, item) {
      // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
      // in pratica, se non c'è Management.vue sulla rotta, pulire e settare solo level 0
      this.emptyQueue();
      this.notifyQueue({
        level: 0,
        path: "",
        text: "Comunicazioni",
      });
      this.$router.push({
        name: "communication_procedures.edit",
        params: {
          id,
          status_procedure: item.status_procedure.value,
          communication_type: item.communication_type.value,
        },
      });
      console.debug("onEdit: ", id, item.status_procedure.value);
    },
    onResume(id, item) {
      console.debug("onResume: ", id, " status: ", item.status_procedure.value);
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Anagrafica eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    openNoteModal(array) {
      this.notes = array;
      this.$bvModal.show("noteDetailModal");
    },
    openDocumentModal(array) {
      this.documents = array;
      this.$bvModal.show("documentDetailModal");
    },
    onSearch(name) {
      let criteria = this.filter;
      // store the filter
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      this.removePaginationByName(name);
      // fetch data
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSelectProvince(selected) {
      this.opt_councils = this.getCouncils()(selected);
      this.filter.byCorrespondence.CITY = null;
    },
    onRemoveProvince() {
      this.opt_councils = [];
      this.filter.byCorrespondence.CITY = null;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
    ...mapActions("lisacomunica_automodal", ["saveLisacomunicaAutoModal"]),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
};
</script>

<template>
  <div>
    <!-- <b-overlay center :show="isLoading" rounded="sm">
      <div v-show="!isLoading"> -->
    <form-wizard
      ref="wizard"
      :startIndex="startIndex"
      @on-complete="onComplete"
      :title="title"
      :subtitle="subtitle"
      :color="color"
      :validateOnBack="true"
      @finish="onFinish"
    >
      <!-- nextButtonText="Avanti"
          finishButtonText="Finisci" -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <!-- remove previous button -->
          <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
        </div>
        <div class="wizard-footer-right">
          <!-- #842 nascondere il tasto 'next' sullo step4-->
          <wizard-button
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            :disabled="isLoading"
            >{{
              props.isLastStep
                ? finished
                  ? "Completato"
                  : "Finisci"
                : "Avanti"
            }}</wizard-button
          >
        </div>
      </template>
      <template slot="step" slot-scope="props">
        <wizard-step
          :tab="props.tab"
          :transition="props.transition"
          :index="props.index"
        >
        </wizard-step>
      </template>

      <tab-content
        v-for="tab in tbs"
        :key="tab.title"
        :title="tab.title"
        :icon="tab.icon"
        :lazy="tab.lazy"
        :before-change="() => validateStep(tab.component, tab.index)"
      >
        <b-overlay center :show="isLoading" rounded="sm">
          <div v-show="!isLoading">
            <!-- $refs.wizard.activeTabIndex -->
            <!-- <b-card
              :header="'Step #' + (tab.index + 1)"
              header-tag="header"
              class="mt-2"
            > -->
            <h5>Step #{{ tab.index + 1 }}</h5>
            <component
              v-if="!tab.hide"
              :ref="tab.component"
              :is="tab.component"
              :resourceId="resourceId"
              :extras="extras"
              @fetch="$emit('fetch')"
              @validate="validateStep"
            ></component>
            <hr />
            <!-- </b-card> -->
          </div>
          <template #overlay>
            <div class="text-center">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
        <!-- @on-validate="mergeTabModel" -->
      </tab-content>
    </form-wizard>
    <!-- </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay> -->
  </div>
</template>
<script>
// //local registration
// import { FormWizard, TabContent } from "vue-form-wizard";
// import "vue-form-wizard/dist/vue-form-wizard.min.css";

import BaseIcon from "@/components/BaseIcon";
import Step1 from "@/components/communication/procedures/steps/step1";
import Step2 from "@/components/communication/procedures/steps/step2";
import Step3 from "@/components/communication/procedures/steps/step3";
import Step4 from "@/components/communication/procedures/steps/step4";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      isLoading: false,
      finished: false,
    };
  },
  components: {
    // FormWizard,
    // TabContent,
    BaseIcon,
    Step1,
    Step2,
    Step3,
    Step4,
    // Step1: () => import("@/components/communication/procedures/steps/step1"),
    // Step2: () => import("@/components/communication/procedures/steps/step2"),
    // Step3: () => import("@/components/communication/procedures/steps/step3"),
    // Step4: () => import("@/components/communication/procedures/steps/step4"),
  },
  props: {
    repository: String,
    resourceId: Number,
    title: String,
    subtitle: String,
    color: String,
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    // stepComponent(component) {
    //   return import(`./steps/${component.toLowerCase()};`);
    // },
    goNext() {
      //alert(this.$refs.wizard.activeTabIndex);
      this.$refs.wizard.nextTab();
    },
    onComplete() {
      if (!this.finished) {
        console.debug("done!");
        this.finished = true;
      } else {
        console.debug("already finished!");
      }
    },
    onFinish() {
      // TODO: $emit('finish') when lastStep completes
      this.finished = true;
    },
    validateStep(name, index) {
      // return this.$refs[name][0].validate();
      const Repo = RepositoryFactory.get(this.repository);
      // se uno step non ha un form, allora usa quello dallo Step1
      let payload =
        this.$refs[name][0].form && this.$refs[name][0].form[this.repository]
          ? this.$refs[name][0].form[this.repository]
          : this.$refs["Step1"][0].form[this.repository];
      // switch (name) {
      //   case "Step1":
      //     payload.status_procedure = 2;
      //     break;
      //   case "Step2":
      //     payload.status_procedure = 3;
      //     break;
      //   case "Step3":
      //     payload.status_procedure = 4;
      //     break;
      //   case "Step4":
      //     payload.status_procedure = 5;
      //     break;
      //   default:
      //     console.info(`Lo step ${name} va implementato.`);
      //     break;
      // }
      switch (index) {
        case 0:
          payload.status_procedure = 2;
          break;
        case 1:
          payload.status_procedure = 3;
          break;
        case 2:
          payload.status_procedure = 4;
          break;
        case 3:
          payload.status_procedure = 5;
          break;
        default:
          console.info(`Lo step ${name} va implementato.`);
          break;
      }
      if (name === this.tabs[this.tabs.length - 1].component) {
        if (this.finished) {
          this.$showSnackbar({
            preset: "info",
            text: `Procedura già completata`,
          });
          return true;
        }
      }
      this.isLoading = true;
      return Repo.update(this.resourceId, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Applicato con successo (${name})`,
          });
          // devo aggiornare status_procedure che tramite prop extras
          // viaggia fino al componente "CommunicationDetail" nel tab General
          this.$emit("status", payload.status_procedure);
          if (name === this.tabs[this.tabs.length - 1].component) {
            if (this.finished) {
              this.finished = true;
            }
          }
          this.isLoading = false;
          // return this.$refs[name][0].validate();
          return true;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
          this.isLoading = false;
          return false;
        });
    },
    // mergeTabModel(model, isValid) {
    //   if (isValid) {
    //     // merging each step model into the final model
    //     this.tabModel = Object.assign({}, this.tabModel, model)
    //   }
    // }
  },
  computed: {
    // stepComponent() {
    //   return (component) => import(`./steps/${component.toLowerCase()};`);
    // },
    tbs() {
      return this.tabs;
    },
    // actTbNdx() {
    //   return this.$refs.wizard.activeTabIndex;
    // },
  },
  beforeMount() {
    if (this.extras.status_procedure > this.tabs.length) {
      this.finished = true;
    }
  },
};
</script>

<style lang="scss" scoped>
// :deep(.wizard-footer-left) {
//   cursor: not-allowed;
//   pointer-events: none;
//   display: none;
// }
// TODO: cambiare il colore del testo del wizard/
:deep(.wizard-title) {
  color: #6f6259;
}
:deep(.vue-form-wizard) {
  .wizard-tab-content {
    min-height: 100px;
    padding: 30px 0px 0px;
  }
}
</style>

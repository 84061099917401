<template>
  <!-- class="h-75 d-inline-block" -->
  <b-modal
    :id="id"
    size="lg"
    modal-tall
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="house"></b-icon>
        <span>Crea riga</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>

    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn @click="cancel()" size="sm" variant="outline-secondary"
          >CHIUDI</b-btn
        >
        <b-btn
          @click="
            cancel();
            saveCommunicationDetail();
          "
          size="sm"
          variant="outline-lisaweb"
          >Salva</b-btn
        >
      </b-form-group>
    </template> -->
    <div class="mx-1">
      <b-overlay center :show="isLoading" rounded="sm">
        <div v-if="!isLoading">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-row style="height: 280px">
                <div class="form-group col-md-3">
                  <base-datepicker
                    vid="date"
                    name="Data"
                    label="Data"
                    v-model="form[rep].custom_data.date"
                    :rules="{ required: true }"
                  />
                </div>
                <!-- </b-row>
              <b-row> -->
                <div class="form-group col-md-3">
                  <base-input
                    vid="description"
                    name="Descrizione"
                    label="Descrizione"
                    v-model="form[rep].custom_data.description"
                    placeholder="Inserisci una descrizione"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="form-group col-md-3">
                  <base-currency
                    vid="gross"
                    name="Valore"
                    label="Valore"
                    v-model="form[rep].custom_data.gross"
                    :options="{
                      currency: 'EUR',
                      locale: 'it-IT',
                      precision: 2,
                    }"
                    :rules="{ required: true }"
                  />
                </div>
              </b-row>
              <b-button
                @click="handleSubmit(saveCommunicationDetail)"
                type="button"
                :disabled="invalid"
                variant="outline-lisaweb"
                size="sm"
                class="mt-2 float-right"
              >
                Salva
              </b-button>
            </b-form>
          </validation-observer>
        </div>
        <template #overlay>
          <div class="text-center">
            <base-icon name="loading" width="35" height="35" />
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
    </div>
  </b-modal>
</template>
<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import { mapActions /* , mapGetters */ } from "vuex";
import FormMixin from "@/mixins/FormMixin";
export default {
  mixins: [FormMixin],
  data() {
    return {
      repository: "communication_detail",
      resource: "communication_details",
      isLoading: true,
      formLoaded: false,
      // showForm: false,
      form: {
        communication_detail: {
          custom_data: {
            date: null,
            description: null,
            gross: null,
          },
        },
      },
    };
  },
  props: {
    id: {
      type: String,
      default: "addCommunicationDetailModal",
    },
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseDatepicker,
    BaseCurrency,
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
  methods: {
    initDefaultFormValues() {},
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    saveCommunicationDetail() {
      this.$bvModal.hide(`${this.id}`);
      this.$emit("create", this.form);
      this.resetForm();
      this.initDefaultFormValues();
    },
  },
  beforeMount() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository)
      .then(() => {
        this.formLoaded = true;
        this.isLoading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  // mounted() {
  //   // usare questo se devo eseguire prima di mostrare
  //   // this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
  //   //   console.debug('Modal addCorrespondenceModals about to be shown', bvEvent, modalId)
  //   // })
  // },
};
</script>

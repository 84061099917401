<template>
  <div class="mx-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-show="!isLoading">
        <div v-if="extras.communication_type === 1">
          <p>SMS</p>
          <sms
            :fields="fields.sms"
            repository="sms"
            filterName="filterSms"
            resource="sms"
            :ref="tableRef.sms"
            hasChecks
            isCheckExclusive
            noActions
          ></sms>
        </div>
        <div v-else-if="extras.communication_type === 0">
          <p>Email</p>
          <email
            :fields="fields.email"
            repository="email"
            filterName="filterEmail"
            resource="email"
            :ref="tableRef.email"
            hasChecks
            isCheckExclusive
            noActions
          ></email>
          <p>PDF</p>
          <pdf
            :fields="fields.pdf"
            repository="pdf"
            filterName="filterPdf"
            resource="pdf"
            :ref="tableRef.pdf"
            hasChecks
            isCheckExclusive
            noActions
          ></pdf>
        </div>
        <div v-else-if="extras.communication_type === 2">
          <p>PDF</p>
          <pdf
            :fields="fields.pdf"
            repository="pdf"
            filterName="filterPdf"
            resource="pdf"
            :ref="tableRef.pdf"
            hasChecks
            isCheckExclusive
            noActions
          ></pdf>
        </div>
        <b-row v-if="isStepReady">
          <div class="form-group col-md-3 align-self-end">
            <p>Invia selezionati</p>
            <b-button
              @click="onSend()"
              type="button"
              variant="lisaweb"
              size="sm"
              :disabled="!canSend"
            >
              Invia
            </b-button>
          </div>
        </b-row>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import Sms from "@/components/tables/Sms.vue";
import Email from "@/components/tables/Email.vue";
import Pdf from "@/components/tables/Pdf.vue";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapActions } from "vuex";
import { RepositoryFactory } from "@/repositories/lisacomunica/RepositoryFactory";

export default {
  mixins: [FormMixin, ConfirmModalMixin],
  data() {
    return {
      isLoading: false, // altrimenti le tableRef non sono già disponibili quando chiama la prima volta computed canSend
      isStepReady: false,
      repository: "communication_procedure",
      resource: "communication_procedures",
      form: {
        communication_procedure: {},
      },
      tableRef: {
        sms: "smsTableRef",
        email: "emailTableRef",
        pdf: "pdfTableRef",
      },
      types: [
        { value: "AVV_SCAD", text: "Avvisi scadenza" },
        { value: "MKTG", text: "Marketing" },
        { value: "SOLL", text: "Solleciti" },
        { value: "REN_PRO", text: "Rendiconti" },
      ],
      fields: {
        sms: [
          {
            key: "check",
            label: "",
            sortable: false,
            thClass: "check",
            class: "check",
          },
          {
            key: "title",
            label: "Titolo",
          },
          {
            key: "type",
            label: "Tipo",
            formatter: (value) => this.getType(value),
          },
          {
            key: "text_content",
            label: "Testo",
          },
        ],
        email: [
          {
            key: "check",
            label: "",
            sortable: false,
            thClass: "check",
            class: "check",
          },
          {
            key: "title",
            label: "Titolo",
          },
          {
            key: "type",
            label: "Tipo",
            formatter: (value) => this.getType(value),
          },
          {
            key: "subject",
            label: "Oggetto",
          },
        ],
        pdf: [
          {
            key: "check",
            label: "",
            sortable: false,
            thClass: "check",
            class: "check",
          },
          {
            key: "title",
            label: "Titolo",
          },
          {
            key: "type",
            label: "Tipo",
            formatter: (value) => this.getType(value),
          },
          {
            key: "header",
            label: "Header",
          },
          {
            key: "body",
            label: "Body",
          },
          {
            key: "ads",
            label: "Ads",
          },
          {
            key: "footer",
            label: "Footer",
          },
        ],
      },
      filterName: {
        sms: "smsFilter",
        email: "emailFilter",
        pdf: "pdfFilter",
      },
    };
  },
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    Sms,
    Email,
    Pdf,
  },
  methods: {
    validate() {
      // change `true` to things checked on model, beyond scope of question
      // this.$emit("on-validate", this.$data, true);
      // this.$emit("on-validate", true, this.index);
      this.$emit("on-validate", true, this.index);
      return true;
    },
    getType(value) {
      return this.types.find((type) => type.value === value).text;
    },
    loadIndex(communication_type) {
      this.isLoading = true;
      this.isStepReady = false;
      if (communication_type === 0) {
        // email
        let Repo = RepositoryFactory.get("email");
        // this.$refs[this.tableRef.email]
        // .fetch()
        Repo.index("type=AVV_SCAD")
          .then((response) => {
            console.log("email", response.data.content);
            this.$refs[this.tableRef.email].items = response.data.content;
            // this.removeFilterByName(name);
            // this.isLoading = false;
            Repo = RepositoryFactory.get("pdf");
            // this.$refs[this.tableRef.pdf]
            //   .fetch()
            Repo.index("type=AVV_SCAD")
              .then((response) => {
                console.log("pdf", response.data.content);
                this.$refs[this.tableRef.pdf].items = response.data.content;
                // this.removeFilterByName(name);
                // this.isLoading = false;
                // this.isStepReady = true;
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: errMsg,
                });
              })
              .finally(() => {
                this.isLoading = false;
                this.isStepReady = true;
              });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          })
          .finally(() => {
            this.isLoading = false;
            this.isStepReady = true;
          });
      } else if (communication_type === 1) {
        const Repo = RepositoryFactory.get("sms");
        // sms
        // this.$refs[this.tableRef.sms]
        //   .fetch()
        Repo.index("type=AVV_SCAD")
          .then((response) => {
            console.log("sms", response.data.content);
            this.$refs[this.tableRef.sms].items = response.data.content;
            // this.removeFilterByName(name);
            // this.isLoading = false;
            // this.isStepReady = true;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          })
          .finally(() => {
            this.isLoading = false;
            this.isStepReady = true;
          });
      } else if (communication_type === 2) {
        const Repo = RepositoryFactory.get("pdf");
        // sms
        // this.$refs[this.tableRef.sms]
        //   .fetch()
        Repo.index("type=AVV_SCAD")
          .then((response) => {
            this.$refs[this.tableRef.pdf].items = response.data.content;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: errMsg,
            });
          })
          .finally(() => {
            this.isLoading = false;
            this.isStepReady = true;
          });
      }
    },
    onSend() {
      this.showConfirm({
        yesCallback: this.send,
        noCallback: null,
        title: "Conferma operazione",
        message: `Stai per inviare tutti i quietanzamenti. Procedere?`,
        yesLabel: "INVIA",
        noLabel: "ANNULLA",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    send() {
      // #842
      // 0. fetch edit per il payload della update
      // 1. put verso la procedura + aggiorna status (next) + snack "Procedura inviata"
      // 2. start procedure_id in lisacomunica
      // 3. redirect a index procedure
      // 4. rollback status_procedure se lisacomunica va in errore

      // 0. fetch edit
      this.fetchEditForm(this.repository, parseInt(this.resourceId))
        .then(() => {
          this.finalize();
          /* .then(() => {
              // // next: finisci (lo faccio nel payload di finalize);
              // @validate nel Wizard rimane (ma inutilizzato)
              // this.$emit("validate", "Step4", 3);
              // 3. redirect to Procedures index
              this.emptyQueue();
              this.notifyQueue({
                level: 0,
                path: "",
                text: "Comunicazioni",
              });
              this.$router.push({
                name: "communication_procedures.index",
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            }) */
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    finalize() {
      // 1. put
      if (this.extras.communication_type === 0) {
        this.form[this.repository].template_id =
          this.$refs[this.tableRef.email].selectedRows[0];
        this.form[this.repository].pdf_template_id =
          this.$refs[this.tableRef.pdf].selectedRows[0];
      } else if (this.extras.communication_type === 1) {
        this.form[this.repository].template_id =
          this.$refs[this.tableRef.sms].selectedRows[0];
      }
      // #842: (aggiornare direttamente anche status_procedure: 5)
      this.form[this.repository].status_procedure = 5;
      this.isLoading = true;
      return this.update(this.repository, this.resourceId)
        .then(() => {
          // 2. start procedure_id (resourceId)
          const Repo =
            this.extras.communication_type === 1
              ? RepositoryFactory.get("sms")
              : RepositoryFactory.get("email");
          let payload = {
            procedure_id: this.resourceId,
          };
          Repo.start(payload)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Procedura Inviata`,
              });
              this.isLoading = false;
              // 3. redirect to Procedures index
              this.emptyQueue();
              this.notifyQueue({
                level: 0,
                path: "",
                text: "Comunicazioni",
              });
              this.$router.push({
                name: "communication_procedures.index",
              });
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
              // 4. rollback status_procedure
              this.form[this.repository].status_procedure = 4;
              return this.update(this.repository, this.resourceId)
                .then(() => {
                  this.$showSnackbar({
                    preset: "success",
                    text: `Azione Completata: Rollback Procedura`,
                  });
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({
                    preset: "error",
                    text: errMsg,
                  });
                })
                .finally(() => {
                  this.isLoading = false;
                });
              // this.isLoading = false; // togliere se abilito il rollback
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
  },
  mounted() {
    let ct = this.extras.communication_type; // === 0 ? "Email" : "Sms"
    // console.log("communication_type:", ct);
    this.loadIndex(ct);
  },
  computed: {
    canSend() {
      // #842: pdf non è obbligatorio
      return this.extras.communication_type === 0
        ? this.$refs[this.tableRef.email].items.some((e) => e.check)
        : this.extras.communication_type === 1
        ? this.$refs[this.tableRef.sms].items.some((e) => e.check)
        : this.extras.communication_type === 2
        ? this.$refs[this.tableRef.pdf].items.some((e) => e.check)
        : false;
    },
  },
};
</script>

<style></style>

<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
          @update="onUpdate"
        >
        </details-card>

        <b-card header="Righe" header-tag="header" class="mt-2">
          <!-- <div
            slot="header"
            class="d-flex justify-content-between align-items-center"
          ></div> -->
          <communication-detail
            :resourceId="resourceId"
            @loadIndex="$emit('loadIndex')"
            :extras="extras"
          >
          </communication-detail>
        </b-card>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import DetailsCard from "@/components/DetailsCard";
import CommunicationDetail from "@/components/communication/groupings/details/CommunicationDetail";

import {
  toInfoData,
  toRelationString,
  joinValues,
  findAndGet,
} from "@/utils/transforms";

export default {
  name: "General",
  mixins: [FormMixin, ShowMixin],
  props: {
    resourceId: Number,
    extras: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseIcon,
    DetailsCard,
    CommunicationDetail,
  },
  data() {
    return {
      details: null,
      repository: "communication_grouping",
      resource: "communication_groupings",
      isLoading: true,
      detailFields: {
        scheda_destinatario: {
          header: "Informazioni",
          edit: false,
          repository: "communication_grouping",
          fields: [
            {
              value: "",
              label: "Destinatario",
              // virtual: (detail) => {
              //   return detail.registries.length
              //     ? detail.registries[0].status_registry.value === 0
              //       ? `${detail.registries[0].attributables.SURN} ${detail.registries[0].attributables.NAME}`
              //       : `${detail.registries[0].attributables.CNAM}`
              //     : "";
              // },
              virtual: (detail) => {
                return toInfoData(detail.registry, "registryfullname", null);
              },
            },
            {
              label: this.getDictionary(
                "attribute_ADDR_value",
                "correspondence"
              ),
              value: "attribute_ADDR_value",
              virtual: (detail) => {
                return toInfoData(detail.registry, "address", 0);
              },
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "address_books",
              virtual: (detail) => {
                return toRelationString(
                  "address_books",
                  detail.registry.address_books,
                  ["MAIL"],
                  [0]
                );
              },
            },
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "address_books",
              virtual: (detail) => {
                return toRelationString(
                  "address_books",
                  detail.registry.address_books,
                  ["CELL"],
                  [0]
                );
              },
            },
          ],
        },
        scheda_note: {
          header: "Note",
          edit: true,
          repository: "communication_grouping",
          fields: [
            {
              value: "note",
              // label: this.getDictionary("claim_type", "claim"),
              label: "Note",
            },
          ],
        },
        // scheda_righe: {
        //   header: "Righe",
        //   edit: false,
        //   repository: "communication_detail",
        //   fields: [
        //     {
        //       value: "",
        //       label: "Dati",
        //       virtual: (detail) => {
        //         return this.toTable(detail);
        //       },
        //     },
        //   ],
        // },
      },
    };
  },
  methods: {
    joinValues,
    findAndGet,
    toLocaleCurrency,
    toLocaleDate,
    fetchForm() {
      this.isLoading = true;
      let queryString = `relations/byRegistry/byInsurancePayment/byBookEntry/byInsuranceAncillary`;
      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          //Set Detail Card Data
          this.details = data;
          this.fetchEditForm(this.repository, this.resourceId)
            .then(() => {
              this.initDetailCardFormData();
              this.isLoading = false;
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: errMsg,
              });
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    onUpdate(form, repository) {
      this.isLoading = true;
      console.log("onUpdate:", form);

      this.update(form, repository).then(() => {
        this.$emit("loadIndex");
        this.fetchForm().then(() => {
          this.isLoading = false;
        });
      });
    },
    update(form, repository) {
      const Repo = RepositoryFactory.get(repository);
      let payload = {};
      payload = { ...this.mandatoryFields[repository] };

      this.setMandatoryValues(repository, payload);

      payload = { ...payload, ...form };
      let resId =
        repository === this.repository
          ? this.resourceId
          : this.repositoryResourceId[repository].id;
      console.log(resId);
      console.log(payload);
      return Repo.update(resId, payload);
    },
    // toTable(item) {
    //   let rows = [];
    //   item.communication_details.forEach((detail) => {
    //     let columns = [
    //       `<td>${this.toLocaleDate(this.toReferringAt(detail))}</td>`,
    //       `<td>${this.toPolicy(detail)}</td>`,
    //       `<td>${this.toDescription(detail)}</td>`,
    //       `<td>${this.toLocaleCurrency(this.toGross(detail))}</td>`,
    //     ];
    //     let row = `<tr>${columns.join("")}</tr>`;
    //     rows.push(row);
    //   });
    //   let ret = `
    //   <table class="table">
    //     <thead>
    //       <th role="columnheader">Data</th>
    //       <th role="columnheader">Polizza</th>
    //       <th role="columnheader">Descrizione</th>
    //       <th role="columnheader">Importo</th>
    //     </thead>
    //     <tbody>
    //     ${rows.join("")}
    //     </tbody>
    //   </table>
    //   `;
    //   return ret;
    // },
    // toPolicy(item) {
    //   /*     @JUNI:
    // se insurance_payment.payment_type.value in 0, 3 vado in book_entry
    //   insurance_payment.book_entry.various_accountings[0].pivot.insurance_policy_number
    // altrimenti vado in insurance_ancillary
    //   insurance_payment.insurance_ancillary.insurance_policy.number */
    //   let ret = "";
    //   if (!item.insurance_payment) {
    //     console.log(`item.id ${item.id} has not insurance_payment`);
    //     return "";
    //   }
    //   let pt = item.insurance_payment.payment_type.value;
    //   try {
    //     switch (pt) {
    //       case 0:
    //       case 3:
    //         ret =
    //           item.insurance_payment.book_entry.various_accountings[0].pivot
    //             .insurance_policy_number;
    //         break;
    //       default:
    //         ret =
    //           item.insurance_payment.insurance_ancillary.insurance_policy
    //             .number;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }

    //   // let hasVariousAccounting = false;
    //   // try {
    //   //   if (
    //   //     hasBookEntry(item.insurance_payment) &&
    //   //     !item.insurance_payment.insurance_ancillary
    //   //   ) {
    //   //     hasVariousAccounting = true;
    //   //   }
    //   //   ret = !hasVariousAccounting
    //   //     ? item.insurance_payment.insurance_ancillary.insurance_policy.number
    //   //     : item.insurance_payment.book_entry.various_accountings[0].pivot
    //   //         .insurance_policy_number || "";
    //   // } catch (error) {
    //   //   console.error(error);
    //   // }
    //   return ret;
    // },
    // toReferringAt(item) {
    //   return item.insurance_payment_id && item.insurance_payment
    //     ? item.insurance_payment.referring_at
    //     : item.custom_data
    //     ? item.custom_data.date
    //     : "not found";
    // },
    // toDescription(item) {
    //   /* ◦ Descrizione: In base al tipo di q:
    //     ▪ Sospeso: mostra il testo “sospeso”
    //     ▪ Acconto: mostra il testo “acconto”
    //     ▪ Da incassare: mostra il prodotto della polizza del q relazionato
    //     ▪ Arretrato: mostra il testo “arretrato”
    //     ▪ Non c’è id di Q: descrizione dal json dell’inserimento manuale

    //     "0":"Sospeso",
    //     "1":"Arretrato",
    //     "2":"Quietanza",
    //     "3":"Acconto",
    //     "4":"Manuale"
    //   */
    //   let ret = "";
    //   if (!item.insurance_payment) {
    //     return item.custom_data ? item.custom_data.description : "not found";
    //   }
    //   let pt = item.insurance_payment.payment_type.value;
    //   try {
    //     switch (pt) {
    //       case 0:
    //         ret = "sospeso";
    //         break;
    //       case 1:
    //         ret = "arretrato";
    //         break;
    //       case 2:
    //       case 4:
    //         // Da incassare
    //         // prodotto: item.insurance_payment.book_entry.various_accountings[0].pivot.insurance_risk.formatted_title
    //         //          tem.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title
    //         ret = `polizza ${item.insurance_payment.insurance_ancillary.insurance_policy.number} ${item.insurance_payment.insurance_ancillary.insurance_policy.insurance_risk.formatted_title}`;

    //         break;
    //       case 3:
    //         ret = "acconto";
    //         break;

    //       default:
    //         break;
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    //   return ret;
    // },
    // toGross(item) {
    //   // Importo: mostra sempre il lordo del Q relazionato con la riga, se non c’è Q mostra il valore dal json dell’inserimento manuale
    //   let ret = "";
    //   if (!item.insurance_payment) {
    //     ret = item.custom_data ? item.custom_data.gross : "not found";
    //   } else {
    //     ret = item.insurance_payment.gross;
    //   }
    //   return ret;
    // },
  },
  mounted() {
    this.isLoading = true;
    this.fetchForm().then(() => {});
  },
  computed: {
    det() {
      return "communication_detail";
    },
  },
};
</script>

<template>
  <div class="mt-1 mx-3">
    <c-groupings></c-groupings>
  </div>
</template>

<script>
import CGroupings from "@/components/communication/groupings/index.vue";
import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  components: {
    CGroupings,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Raggruppamenti",
      path: "communication_groupings",
      level: 1,
    });
  },
};
</script>

<template>
  <div class="mt-1 mx-3">
    <c-procedures> </c-procedures>
  </div>
</template>

<script>
import CProcedures from "@/components/communication/procedures/index.vue";
import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  components: {
    CProcedures,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "LisaComunica",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Procedure ",
      path: "communication_procedures",
      level: 2,
    });
  },
};
</script>
